import React, { Key, useEffect } from 'react';
import Table from "react-bootstrap/Table";

import { Row, Col, Button} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SendQuotation.scss';
import { Controller, useForm } from 'react-hook-form';
import api from '../../../../../../services/api';

export interface propInsert {
    info: any,
    setModalSuccess: any, 
    setModalContent: any, 
    setLog: any, 
    setResponseText: any,
    t?: any
}

const SendQuotation: React.FC<propInsert> = ({
    info, setModalSuccess, setModalContent, setLog, setResponseText, t
}: propInsert) => {


    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function onSubmit(obj: any) {

        if(!obj.email1 && !obj.email2 && !obj.email3) {
            setResponseText(t('groupsAndCustomized.report.sendQuotationAction.emailRequired'));
            setModalContent("warning");
            setLog(2);
            setModalSuccess(true);
            return;
        }

        setModalContent("loading");
        setModalSuccess(true);
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditAccounts = async () => {
            try {

                const { data } = await api.post(`GroupFile/SendQuotationReportPdf`, {
                    fileId: info.id,
                    recipients: [
                        obj.email1, obj.email2, obj.email3
                    ]
                }, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;

                    setResponseText(response.texto);
                    setLog(response.log);

                    if(response.log === 1) {
                        setModalContent("error");
                    } else {
                        setModalContent("success");
                    }
                    
                }
            } catch (error: any) {
                setResponseText("Erro ao tentar enviar cotação");
                setModalContent("error");

                // if (error?.response?.status === 401) {
                //     window.location.href = window.location.origin + '/';
                // }
                // if (error?.response?.status === 400) {
                //     window.location.href = window.location.origin + '/list-groups-customized';
                // }
            }
        }
        EditAccounts();
    }

    if (info !== null) {

        return (
            <>
                <Form className="h-100" noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12}>
                                    <Form.Label>{t("groupsAndCustomized.report.sendQuotationAction.email1")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="email1"
                                        //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                //label={t("groupsAndCustomized.observation")}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='off'
                                                //value={observation}
                                                //onChange={(e: any) => { setObservation(e.target.value); field.onChange(e); }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12}>
                                    <Form.Label>{t("groupsAndCustomized.report.sendQuotationAction.email2")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="email2"
                                        //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                //label={t("groupsAndCustomized.observation")}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='off'
                                                //value={observation}
                                                //onChange={(e: any) => { setObservation(e.target.value); field.onChange(e); }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12}>
                                    <Form.Label>{t("groupsAndCustomized.report.sendQuotationAction.email3")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="email3"
                                        //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                //label={t("groupsAndCustomized.observation")}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='off'
                                                //value={observation}
                                                //onChange={(e: any) => { setObservation(e.target.value); field.onChange(e); }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                        <div className='mt-4 d-flex justify-content-end'>
                            <Button type="submit" className="form-button">{t('groupsAndCustomized.report.sendQuotationAction.send')}</Button>
                        </div>
                    </div>
                </Form>
            </>
        )
    } else {
        return (<></>);
    }

}

export default SendQuotation;