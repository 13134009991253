import React, { useState } from "react";
import api from '../../../../services/api';

import { Form, Modal, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableBillsReceive.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import Insert from "./components/Insert/Insert";
import SingleNote from "./components/SingleNote/SingleNote";
import CreditNote from "./components/CreditNote/CreditNote";
import Invoice from "./components/Invoice/Invoice";
import ExpirationReceive from "./components/ExpirationReceive/ExpirationReceive";
import FormBillsToPay from "../../../../components/Forms/FormBillsToPay/FormBillsToPay";
import FormBillReceiveEdit from "../../../../components/Forms/FormBillReceiveEdit/FormBillReceiveEdit";
import EditDetached from "./components/EditDetached/EditDetached";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import ModalQrCodePixPayment from "./components/ModalQrCodePixPayment/ModalQrCodePixPayment";

export interface propTable {
  billsReceive: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
  exportBills: any;
  printBills: any;
  totalBillsReceive?: any;
  actualDate: any;
  parameters?: any;
  refreshData?: any;
}

const TableBillsReceive: React.FC<propTable> = ({
  billsReceive, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading, exportBills, printBills, totalBillsReceive, actualDate, parameters,
  refreshData
}: propTable) => {
  const { t } = useTranslation();

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [expiration, setExpiration] = useState<any>();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>(null);
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);

  const [editShow, setEditShow] = useState<any>(false);
  const [editDetachedShow, setEditDetachedShow] = useState<any>(false);

  const [undoSettlementModalShow, setUndoSettlementModalShow] = useState<any>(false);
  const [undoSettlementText, setUndoSettlementText] = useState<any>("");

  const [genQrCodePixModalShow, setGenQrCodePixModalShow] = useState<any>(false);
  const [genQrCodePixResponse, setGenQrCodePixResponse] = useState<any>(null);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  async function settleBills(row: any) {
    setSelectedSingleRow(row);  //recebe array vazio ([]) para liquidar consulta
    setModalShow(true);
  }

  async function editDetachedBill(row: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };
    try {
      const res = await api.post(`/BillsReceivable/GetBill`,
        {
          "id": row.newPaymentsId,
          "tipo": 1,

        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setSelectedSingleRow(dados);
        setEditDetachedShow(true);
      }
    } catch (error: any) {

    }
  }

  function unsettleBill(row: any) {
    setSelectedSingleRow(row);

    if (row.idLote > 0) {
      setUndoSettlementText("Liquidação em lote, deseja mesmo desfazer a liquidação? A liquidação de todo o lote sera desfeita");
    } else {
      setUndoSettlementText("Deseja mesmo desfazer a liquidação do pagamento?");
    }

    setUndoSettlementModalShow(true);
  }

  async function handleActionUndoSettlementModal() {
    setUndoSettlementModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.newPaymentsId;
    var tipo = 1;

    if (selectedSingleRow.nParcela !== 1) {
      manifestId = selectedSingleRow.installmentsId;
      tipo = 2;
    }

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsReceivable/UndoBillSettlement`,
        {
          "manifestId": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText("Erro, tente novamente mais tarde");
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function editRow(row: any) {

    if (row.notaAvulsa === 5) {
      await editDetachedBill(row);
    } else {
      setSelectedSingleRow(row);
      setEditShow(true);
    }
  }

  async function genQrCodePix(row: any) {
    setSelectedSingleRow(row);
    setGenQrCodePixModalShow(true);
  }

  async function handleActionGenQrCodePixModal() {
    setGenQrCodePixModalShow(false);

    setModalContent("loading");
    setModalResponse(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var paymentId = selectedSingleRow.newPaymentsId;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsReceivable/ProcessBillPaymentViaKoinPix`,
        {
          "paymentId": paymentId
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;

        // chamar endpoint e mostrar qrcode do pix no modal
        setGenQrCodePixResponse(res.data);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        //setResponseText("Erro, tente novamente mais tarde");
        //setLog(1);
      }
    } catch (error: any) {
      setModalContent("error");
      console.log(error.response)
    }
  }

  const handleActionBillEditModal = () => {
    setSelectedSingleRow(null);
    setEditDetachedShow(false);
    setEditShow(false);
    refreshData();
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function addActionButton(cell: any, row: any) {
    let nivel: any = JSON.parse(localStorage.getItem("c2tourAuth") || '{}')?.user?.perfil?.nivel

    nivel = nivel ? atob(nivel) : 0

    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {row.sufixovalor !== 'TX' ?
                (row.statusPagamento === 'Recebido' ?
                  <Dropdown.Item
                    eventKey="1"
                    disabled={nivel === '29'}
                    onClick={() => { unsettleBill(row) }}>
                    <FontAwesomeIcon
                      icon={['fal', 'strikethrough']}
                    //className="mr-2"
                    />
                    Desfazer Liquidação
                  </Dropdown.Item>
                  :
                  (row.statusPagamento !== 'Faturado' ?
                    <Dropdown.Item
                      eventKey="1"
                      disabled={nivel === '29'}
                      onClick={() => { settleBills([row]) }}>
                      <FontAwesomeIcon
                        icon={['fal', 'dollar-sign']}
                      //className="mr-2"
                      />
                      Liquidar
                    </Dropdown.Item>
                    : <></>)
                )
                :
                <></>
              }
              <Dropdown.Item
                eventKey="3"
                disabled={nivel === '29'}
                onClick={() => { editRow(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                //className="mr-2"
                />
                {t('suppliersTable.edit')}
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="4"
                disabled={nivel === '29' || row.formaPagamento !== "Faturado" || row.statusReserva !== "Confirmada"}
                onClick={() => { genQrCodePix(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'qrcode']}
                //className="mr-2"
                />
                Gerar Pix
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell}</span>
      </>
    )
  }

  function formatValue(cell: any, row: any) {
    if (cell) {
      return (
        <>
          <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")} {row.sufixovalor}</span>
        </>
      )
    } else {
      return (
        <>
          <span className=""></span>
        </>
      )
    }
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-analise"
            : cell === "Confirmada"
              ? "bg-ativo"
              : cell === "Cancelada"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  function addPayment(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-inativo"
            : cell === "Recebido"
              ? "bg-ativo"
              : "bg-analise"
        }
      >
        {cell}
      </div>
    );
  }

  function addErp(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-inativo"
            : //cell === "Exportado"
            "bg-analise"
        }
      >
        {cell}
      </div>
    );
  }

  const renderFatura = (cell:any, row:any)=> {  
   if(!cell){
    return 'Não informado'
   }
    return {cell}
   
  }

  const columns = [
    {
      dataField: "vencimento",
      text: "Vencimento",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "formaPagamento", text: "Forma de Pagamento", sort: true },
    { dataField: "nFatura", text: "Nº Fatura", formatter: renderFatura },
    { dataField: "idMov", text: "Id Mov." },
    { dataField: "agente", text: "Agente", sort: true },
    
    {
      dataField: "dataReserva",
      text: "Data Reserva",
      sort: true,
      formatter: convertDate,
    },
    {
      dataField: "dataOperacao",
      text: "Data Operação",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "tour", text: "Tour", sort: true },
    { dataField: "cliente", text: "Cliente", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: formatValue,
    },
    {
      dataField: "statusReserva",
      text: "Status Reserva",
      sort: true,
      formatter: addStatus,
    },
    {
      dataField: "statusPagamento",
      text: "Status Pagamento",
      sort: true,
      formatter: addPayment,
    },
    {
      dataField: "statusErp",
      text: "Status ERP",
      sort: true,
      formatter: addErp,
    },
    { dataField: "id", text: "Ações", formatter: addActionButton },
  ];

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "contas", text: "Contas" }];

  const notFoundProducts = [{ contas: "Nenhuma conta encontrada" }];
  /* [END] In case of empty table*/

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando contas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.statusReserva === "Pendente") {
      return 'border-analise';
    } else if (row.statusReserva === "Confirmada") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };


  /*NOTA AVULSA PESQUISAR */

  const [singleNoteInfo, setSingleNoteInfo] = useState<any>(undefined);

  const searchSingleNote = (localizador: any = '') => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function getSingleSearch() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetOrderInfo/${localizador}`, config);
        if (data.statusCode !== 400) {
          //;
          setSingleNoteInfo(data.data);
        }
      } catch (error: any) {
        console.log(error);
        //if (error.response.status === 401) {
          //
        //}
      }
    }
    getSingleSearch();
  }

  function closeAction() {
    setSingleNoteInfo(undefined);
  }
  /* [END] NOTA AVULSA PESQUISAR */


  const onSubmit = (data: any) => {
    setModalContent("loading");
    setModalSuccess(true);

    var fixDate = datapgto.split('/');
    data.dataPgto = `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}`;
    data.contaLiquidacao = data.contaLiquidacao ? data.contaLiquidacao.id : 0;


    var valor: any = selectedSingleRow.length > 0 ? selectedSingleRow[0].valor : 0;

    var items: any = selectedSingleRow.length > 0 ? [{
      "id": parseInt((selectedSingleRow[0].nParcela > 1) ? selectedSingleRow[0].installmentsId : selectedSingleRow[0].newPaymentsId),
      "tipo": ((selectedSingleRow[0].nParcela > 1) ? 2 : 1) //0//itemsToSettle[i].tipo
    }] : [];

    async function settleQuery() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const res = await api.post('/BillsReceivable/SettleBills',
          {
            "parametros": selectedSingleRow.length > 0 ? null : parameters, // objeto com os parametros da consulta
            "items": items, // array com os itens a serem liquidados
            "valor": valor,
            "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
            "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
            "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
            "dataPgto": data.dataPgto, // data do pagamento
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );
        if (res.status !== 400) {
          setResponseText(res.data.data.texto);
          setLog(res.data.data.log);

          if (res.data.data.log === 0) {
            setModalContent('success');
            //setAllowReload(true);
            setModalShow(false);
          }
          else if (res.data.data.log === 1) {
            setModalContent('error');
          }
        }
      } catch (error: any) {

        if (error?.response?.status === 401) {
          //window.location.href = window.location.origin + '/';
        }
      }
    }

    settleQuery();
  }

  function handleReload() {
    window.location.reload();
  }

  function validaFaturar() {
    if (parameters.formaPagamento !== 5) {
      setResponseText('Selecione Forma de Pagamento Faturado');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
      //console.log('aqui');
    } else if (parameters.agente === '' || parameters.agente === '0' || parameters.agente === null) {
      setResponseText('Selecione um único Agente');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
    } else if ((parameters.dataInicial === '') || (parameters.dataFinal === '')) {
      setResponseText('Selecione o periodo de data');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
    }
  }

  if (billsReceive !== null) {
    let nivel: any = JSON.parse(localStorage.getItem("c2tourAuth") || '{}')?.user?.perfil?.nivel

    nivel = nivel ? atob(nivel) : 0

    return (
      <>
        <div className="table-default bills-receive-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  {nivel !== '29' &&
                    <>
                      <div>
                        <ModalDefault
                          title="Inserir Recebimento"
                          name="Inserir"
                          icon="yes"
                          typeIcon="plus-circle"
                          classBtn="btn btn-bills"
                          classModal="modal-large"
                        >
                          <Insert
                            show={setModalSuccess}
                            setResponseText={setResponseText}
                            setModalContent={setModalContent}
                            setLog={setLog}
                          />
                        </ModalDefault>
                      </div>
                      <div>
                        <ModalDefault
                          title="Nota Avulsa"
                          name="Nota Avulsa"
                          icon="yes"
                          typeIcon="plus-circle"
                          classBtn="btn btn-bills"
                          classModal="modal-large"
                          localation="yes"
                          getLocalizador={searchSingleNote}
                          closeAction={closeAction}
                        >
                          <SingleNote
                            singleNoteInfo={singleNoteInfo}
                            searchSingleNote={searchSingleNote}
                          />
                        </ModalDefault>
                      </div>
                      <div>
                        <ModalDefault
                          title="Nota de Crédito"
                          name="Nota de Crédito"
                          icon="yes"
                          typeIcon="plus-circle"
                          classBtn="btn btn-bills"
                          classModal="modal-large"
                          localation="yes"
                          getLocalizador={searchSingleNote}
                          closeAction={closeAction}
                        >
                          <SingleNote singleNoteInfo={singleNoteInfo} searchSingleNote={searchSingleNote} isCreditNote={true} />
                        </ModalDefault>
                      </div>
                    </>
                  }
                  <div>
                    <button className="btn btn-bills p-0">
                      <Dropdown drop="down">
                        <Dropdown.Toggle variant="light ">
                          <FontAwesomeIcon
                            icon={["fal", "share"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          Exportar
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="1" onClick={() => { exportBills(1) }} >  Layout Padrão</Dropdown.Item>
                          <Dropdown.Item eventKey="2" onClick={() => { exportBills(2) }} >  Layout Azul Viagens</Dropdown.Item>
                          <Dropdown.Item eventKey="3" onClick={() => { exportBills(3) }} >  Layout HotelBeds</Dropdown.Item>
                          <Dropdown.Item eventKey="4" onClick={() => { exportBills(4) }} >  Layout Cartão de Crédito</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </button>
                  </div>
                  {nivel !== '29' &&
                    <>
                      <div>
                        {parameters.formaPagamento !== 5 ||
                          parameters.agente === '' || parameters.agente === '0' || parameters.agente === null ||
                          parameters.dataInicial === '' ||
                          parameters.dataFinal === '' ?
                          <button className="btn btn-bills" type="button" onClick={validaFaturar}>
                            <FontAwesomeIcon
                              file-invoice
                              icon={["fal", "file-invoice"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            /> Faturar
                          </button>
                          :
                          <ModalDefault
                            title="Faturar"
                            name="Faturar"
                            icon="yes"
                            typeIcon="file-invoice"
                            classBtn="btn btn-bills"
                            classModal="modal-large"
                          >
                            <Invoice
                              billsReceive={billsReceive}
                              totalRows={totalRows}
                              pageCount={pageCount}
                              rowsPerPage={rowsPerPage}
                              parameters={parameters}
                              setModalSuccess={setModalSuccess}
                              setModalContent={setModalContent}
                              setResponseText={setResponseText}
                              setLog={setLog}
                            />
                          </ModalDefault>
                        }
                      </div>
                      <div>
                        <Dropdown drop="down" >
                          <Dropdown.Toggle className="menu-profile p-0">
                            <button className="btn btn-bills">
                              <FontAwesomeIcon
                                icon={["fal", "dollar-sign"]}
                                size="1x"
                                style={{ marginRight: "5px" }}
                              />
                              Liquidar em Lote
                            </button>
                          </Dropdown.Toggle>

                          <Dropdown.Menu id="dropdown-menu-liquidar">
                            <Dropdown.Item eventKey="1" disabled={false} onClick={() => { window.location.href = "/settle-bills-to-receive" }}> Liquidar Parcial</Dropdown.Item>
                            <Dropdown.Item eventKey="2" disabled={false} onClick={() => { settleBills([]) }}> Liquidar Consulta</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div>
                        <ModalDefault
                          title="Vencimento"
                          name="Alterar Vencimento em Lote"
                          icon="yes"
                          typeIcon="calendar"
                          classBtn="btn btn-bills"
                          classModal="modal-large modal-large-custom"
                          vencimento="yes"
                          setValue={setExpiration}
                        >
                          <ExpirationReceive
                            billsReceive={billsReceive}
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            setExpiration={setExpiration}
                            expiration={expiration}
                            actualDate={actualDate}
                            parameters={parameters}
                          />
                        </ModalDefault>
                      </div>
                    </>
                  }
                </div>
                <div className="d-flex align-items-center" style={{ textAlign: "right" }}>
                  <h5 className="mb-0">Total: <span className={Math.sign(totalBillsReceive) === -1 ? "negative" : ""}>{totalBillsReceive.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h5>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsReceive}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        {/* Modal de Liquidar */}
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <h3>
                Liquidar
              </h3>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
              <FormBillsToPay control={control} errors={errors} datapgto={datapgto} setDatapgto={setDatapgto} modalResponse={modalResponse} setModalResponse={setModalResponse} billsReceive={true} />
            </Form>
          </>
        </Modal>
        {/* END - Modal de Liquidar */}

        {/* Modal de Editar */}
        <Modal
          className="modalReceber"
          show={editShow}
          onHide={handleActionBillEditModal}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header className="p-3" closeButton></Modal.Header>
            <FormBillReceiveEdit action={handleActionBillEditModal} info={selectedSingleRow} />
          </>
        </Modal>
        {/* END - Modal de Editar */}

        {/* Modal de Editar Avulso */}
        <div>
          {/* title="Inserir Pagamento"
                      name="Inserir"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large" */}
          <Modal
            className={"modal-custom modalAuth modal-large"}
            show={editDetachedShow}
            onHide={handleActionBillEditModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header closeButton>
                <h3>
                  Editar Pagamento
                </h3>
              </Modal.Header>
              <hr style={{ margin: "30px 0" }} />
              <EditDetached
                show={setModalSuccess}
                setResponseText={setResponseText}
                setModalContent={setModalContent}
                setLog={setLog}
                info={selectedSingleRow}
              />
            </>
          </Modal>
        </div>
        {/* END - Modal de Editar Avulso */}

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => {setModalSuccess(false); setModalShow(false);}}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}

        {/* modal undo settlement */}
        <ModalQuestion
          modalShow={undoSettlementModalShow}
          setModalShow={setUndoSettlementModalShow}
          titulo="Desfazer Liquidação"
          mensagem={undoSettlementText}
          handleActionConfirmModal={handleActionUndoSettlementModal}
        />
        {/* END - modal undo settlement */}

        {/* modal generate qrcode pix */}
        <ModalQuestion
          modalShow={genQrCodePixModalShow}
          setModalShow={setGenQrCodePixModalShow}
          titulo="Gerar QrCode Pix"
          mensagem={"Deseja gerar um pix para o pagamento da reserva?"}
          handleActionConfirmModal={handleActionGenQrCodePixModal}
        />

        <Modal
            className="modal-custom modalGroupFile modalGroupFilePayment"
            show={modalResponse !== null}
            onHide={() => {
                // setCheckoutResponse('');
                setModalContent('loading');
                setModalResponse(null)
                //if (paymentMethod !== '111') {
                //    resetForm();
                //}
                if(modalContent === "success") {
                    window.location.href = window.location.origin + '/list-groups-customized';
                }
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
        >
            <>
              {modalContent === "success" && <Modal.Header closeButton></Modal.Header>}
              <Modal.Body>
                <ModalQrCodePixPayment
                  checkoutResponse={genQrCodePixResponse}
                  //pix={true}
                  setModalResponse={setModalResponse}
                  modalContent={modalContent}
                  resetCart={handleReload}
                  watchLanguage={'1'}
                />
              </Modal.Body>
            </>
        </Modal>
        
        {/* END - generate qrcode pix */}
      </>
    );
  } else if (billsReceive === null && loading === true) {
    return (
      <>
        <div className="table-default bills-receive-table loading not-found">
          <div className="table-container">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>

              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-receive-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableBillsReceive;
