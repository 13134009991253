import { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './infra/PrivateRoute';
//import RedirectLogin from './infra/RedirectLogin';
// import { parseCookies } from 'nookies';

import NotFound from './pages/NotFound/NotFound/NotFound';
import PreLoader from './components/PreLoader/PreLoader';

import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import NewPassword from './pages/NewPassword/NewPassword';

import Users from './pages/Users/Users';
import NewUser from './pages/Users/NewUser';
import EditUser from './pages/Users/EditUser';

import Dashboard from './pages/Dashboard/Dashboard';

import Checkout from './pages/Checkout/Checkout';

/* Reservation */
import ListReservations from './pages/Reservations/ListReservations/ListReservations';
import Sales from './pages/Sales/Sales';
/* END - Reservation */

import ListPartners from './pages/Partners/ListPartners/ListPartners';
import AddPartners from './pages/Partners/AddPartners/AddPartners';
import EditPartners from './pages/Partners/EditPartners/EditPartners';

import AddSuppliers from './pages/Suppliers/AddSuppliers/AddSuppliers';
import ListSuppliers from './pages/Suppliers/ListSuppliers/ListSuppliers';
import EditSuppliers from './pages/Suppliers/EditSuppliers/EditSuppliers';

import AddAccounts from './pages/Accounts/AddAccounts/AddAccounts';
import EditAccounts from './pages/Accounts/EditAccounts/EditAccounts';
import ListAccounts from './pages/Accounts/ListAccounts/ListAccounts';

//Financial
import AddMerchantFlags from './pages/MerchantFlags/AddMerchantFlags/AddMerchantFlags';
import ListMerchantFlags from './pages/MerchantFlags/ListMerchantFlags/ListMerchantFlags';
import EditMerchantFlags from './pages/MerchantFlags/EditMerchantFlags/EditMerchantFlags';

import AddMerchants from './pages/Merchants/AddMerchants/AddMerchants';
import ListMerchants from './pages/Merchants/ListMerchants/ListMerchants';
import EditMerchants from './pages/Merchants/EditMerchants/EditMerchants';

import BillsToPay from './pages/BillsToPay/BillsToPay';
import SettleBillsToPay from './pages/SettleBillsToPay/SettleBillsToPay';
import BillsToReceive from './pages/BillsToReceive/BillsToReceive';
import SettleBillsToReceive from './pages/SettleBillsToReceive/SettleBillsToReceive';
import Manifest from './pages/Manifest/Manifest';
import SingleSession from './pages/SingleSession/SingleSession';

import SessionClosure from './pages/SessionClosure/SessionClosure';
import SessionBillsToReceive from './pages/SessionClosure/SessionBillsToReceive/SessionBillsToReceive';
import SessionBillsToPay from './pages/SessionClosure/SessionBillsToPay/SessionBillsToPay';
import SessionBilling from './pages/SessionClosure/SessionBilling/SessionBilling';
import PictureOfTheDay from './pages/SessionClosure/PictureOfTheDay/PictureOfTheDay';

import DecolarImport from './pages/DecolarImport/DecolarImport';

import ReportFinancial from './pages/ReportFinancial/ReportFinancial';

import Coupons from './pages/Coupons/Coupons';
import Commissions from './pages/Commissions/Commissions';
import SingleSessionTransfer from './pages/Manifest/components/TableManifest/components/SingleSessionTransfer/SingleSessionTransfer';

import SalesReport from './pages/SalesReport/SalesReport';
import MarketingSalesReport from './pages/MarketingSalesReport/MarketingSalesReport';

//import Chart from './pages/Chart/Chart';
import Quotation from './pages/Quotation/Quotation';
import InvoicedSales from './pages/InvoicedSales/InvoicedSales';

import Pickup from './pages/Pickup/Pickup';
import AddPickup from './pages/Pickup/components/AddPickup/AddPickup';
import EditPickup from './pages/Pickup/components/EditPickup/EditPickup';

import RevokeCache from './pages/RevokeCache/RevokeCache';

import Products from './pages/Products/Products';
import ProductList from './pages/Products/components/ProductList/ProductList';

import Vehicles from './pages/Vehicles/Vehicles';
import AddVehicle from './pages/Vehicles/AddVehicle';
import EditVehicle from './pages/Vehicles/EditVehicle';

import Zones from './pages/Zones/Zones';
import AddZone from './pages/Zones/AddZone';
import EditZone from './pages/Zones/EditZone';

import ListCategories from './pages/Categories/ListCategories/ListCategories';
import AddCategory from './pages/AddCategory/AddCategory';

import AssociateCategories from './pages/Categories/AssociateCategories/AssociateCategories';

import EditAssociation from './pages/Categories/EditAssociation/EditAssociation';

import Resources from './pages/Resources/Resources';
import AddResource from './pages/Resources/components/AddResource/AddResource';
import EditResource from './pages/Resources/components/EditResource/EditResource';

import ResourceType from './pages/ResourceType/ResourceType';
import AddResourceType from './pages/ResourceType/components/AddResourceType/AddResourceType';
import EditResourceType from './pages/ResourceType/components/EditResourceType/EditResourceType';

import ResourceToAssociate from './pages/ResourceToAssociate/ResourceToAssociate';

import SuppliersTariff from './pages/SuppliersTariff/SuppliersTariff';
import AddSuppliersTariff from './pages/SuppliersTariff/AddSuppliersTariff/AddSuppliersTariff';
import EditSuppliersTariff from './pages/SuppliersTariff/EditSuppliersTariff/EditSuppliersTariff';
import CopySuppliersTariff from './pages/SuppliersTariff/CopySuppliersTariff/CopySuppliersTariff';
import EditCategory from './pages/Categories/ListCategories/components/EditCategory/EditCategory';

import AddTransfer from './pages/ProductTransfer/AddTransfer';
import TransferList from './pages/ProductTransfer/TransferList/TransferList';
import EditTransfer from './pages/ProductTransfer/EditTransfer';

import AddTour from './pages/ProductTour/AddTour';
import EditTour from './pages/ProductTour/EditTour';
import MigrationTour from './pages/ProductTourMigration/EditTour';

import AddTicket from './pages/ProductTicket/AddTicket';
import EditTicket from './pages/ProductTicket/EditTicket';

import Stock from './pages/Stock/Stock';
import ListStock from './pages/StockList/StockList';
import StockGroupSession from './pages/StockGroupSession/StockGroupSession';
import EditStockGroupSession from './pages/StockGroupSession/EditStockGroupSession';
import ListStockGroupSession from './pages/StockGroupSession/ListStockGroupSession/ListStockGroupSession';

import AddCombo from './pages/Combo/AddCombo';

import AddBanners from './pages/AddBannersNew/AddBanners'
import ReportRioTour from './pages/ReportRioTour/ReportRioTour';
import OpeningHours from './pages/OpeningHours/OpeningHours';
import ModalControl from './pages/ModalControl/ModalControl';

import AccountsExtract from './pages/AccountsExtract/AccountsExtract';
import CashierExtract from './pages/AccountsExtract/CashierExtract/CashierExtract';
import ReportCashFlow from './pages/ReportCashFlow/ReportCashFlow';
import SessionlessOrders from './pages/SessionlessOrders/SessionlessOrders';
import ReportDailyReceipt from './pages/ReportDailyReceipt/ReportDailyReceipt';
import ListAssociation from './pages/Categories/ListAssociation/ListAssociation';
import BillsToReceiveERP from './pages/BillsToReceiveERP/BillsToReceiveERP';
import BillsToPayERP from './pages/BillsToPayERP/BillsToPayERP';
import RPSIssuance from './pages/RPSIssuance/RPSIssuance';
import CardConciliation from './pages/CardConciliation/CardConciliation';
import SubordinateExtract from './pages/SubordinateExtract/SubordinateExtract';
import ExportERPSales from './pages/ExportERPSales/ExportERPSales';
import ExportERPSuppliers from './pages/ExportERPSuppliers/ExportERPSuppliers';
import ExportERPSpreadsheet from './pages/ExportERPSpreadsheet/ExportERPSpreadsheet';
import ExportERPLogs from './pages/ExportERPLogs/ExportERPLogs';

import SessionClosed from './pages/SessionClosure/SessionClosed/SessionClosed';

import ListBlackout from './pages/Blackout/ListBlackout/ListBlackout';
import AddBlackout from './pages/Blackout/AddBlackout';
import EditBlackout from './pages/Blackout/EditBlackout';

import ReportCardConciliation from './pages/ReportCardConciliation/ReportCardConciliation';
import ReportERPConference from './pages/ReportERPConference/ReportERPConference';
import ReportSessionPend from './pages/ReportSessionPend/ReportSessionPend';
import EditCombo from './pages/Combo/EditCombo';
import ListCombo from './pages/Combo/ListCombo';

import ReportSalesPend from './pages/ReportSalesPend/ReportSalesPend';

import ListChannels from './pages/Channel/ListChannels';
import ListSources from './pages/Source/ListSources';

import AddGroupFile from './pages/GroupsAndCustomized/AddGroupFile/AddGroupFile';
import GroupsAndCustomized from './pages/GroupsAndCustomized/GroupsAndCustomized';
import EditGroupFile from './pages/GroupsAndCustomized/EditGroupFile/EditGroupFile';
import CopyGroupFile from './pages/GroupsAndCustomized/CopyGroupFile/CopyGroupFile';
import ResourcesVehicleRanking from './pages/Resources/ResourcesVehicleRanking/ResourcesVehicleRanking';

function Routes() {
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initializing: true
  });

  // const cookies = parseCookies();

  useEffect(() => {
    const objAuth: any = localStorage.getItem('c2tourAuth');

    if (objAuth !== null/*  && cookies.authCookie */) {
      setAuthState({
        authenticated: true,
        initializing: false
      });
    } else {
      setAuthState({
        authenticated: false,
        initializing: false
      });

    }

  }, [setAuthState]);

  if (authentication.initializing) {
    return <div><PreLoader /></div>;
  }

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route exact path="/profile" component={Profile} />
      {/* <RedirectLogin exact path="/" isAuthenticated={authentication.authenticated} component={Login} /> */} {/* Teste renan */}

      <Route exact path="/forgot_password" component={ForgotPassword} />
      <Route exact path="/new_password/:token" component={NewPassword} />

      <PrivateRoute exact path="/dashboard" isAuthenticated={authentication.authenticated} component={Dashboard} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 26, 29]} />
      <PrivateRoute exact path="/sale" isAuthenticated={authentication.authenticated} component={Sales} level={[19, 1, 2, 3, 7, 8, 9, 10, 23, 24, 25, 29]} />
      <PrivateRoute exact path="/checkout" isAuthenticated={authentication.authenticated} component={Checkout} level={[19, 1, 2, 3, 10, 23, 24, 25, 29]} />
      <PrivateRoute exact path="/reservations" isAuthenticated={authentication.authenticated} component={ListReservations} level={[19, 1, 2, 3, 10, 11, 12, 13, 14, 16, 17, 23, 24, 25, 26, 29]} />

      <PrivateRoute exact path="/partners" isAuthenticated={authentication.authenticated} component={ListPartners} level={[19, 1, 2, 3, 7, 8, 9, 10, 11, 12]} />
      <PrivateRoute exact path="/partners/add-partners" isAuthenticated={authentication.authenticated} component={AddPartners} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/partners/edit-partners" isAuthenticated={authentication.authenticated} component={EditPartners} level={[19, 3, 10, 11, 12]} />

      <PrivateRoute exact path="/suppliers" isAuthenticated={authentication.authenticated} component={ListSuppliers} level={[19, 1, 2, 3, 7, 8, 9, 10, 11, 12]} />
      <PrivateRoute exact path="/suppliers/add-suppliers" isAuthenticated={authentication.authenticated} component={AddSuppliers} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/suppliers/edit-suppliers" isAuthenticated={authentication.authenticated} component={EditSuppliers} level={[19, 3, 10, 11, 12]} />

      <PrivateRoute exact path="/accounts" isAuthenticated={authentication.authenticated} component={ListAccounts} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/accounts/add-accounts" isAuthenticated={authentication.authenticated} component={AddAccounts} level={[19, 10]} />
      <PrivateRoute exact path="/accounts/edit-accounts" isAuthenticated={authentication.authenticated} component={EditAccounts} level={[19, 10]} />

      <PrivateRoute exact path="/merchants" isAuthenticated={authentication.authenticated} component={ListMerchants} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/merchants/add-merchants" isAuthenticated={authentication.authenticated} component={AddMerchants} level={[19, 10]} />
      <PrivateRoute exact path="/merchants/edit-merchants" isAuthenticated={authentication.authenticated} component={EditMerchants} level={[19, 10]} />

      <PrivateRoute exact path="/merchantflags" isAuthenticated={authentication.authenticated} component={ListMerchantFlags} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/merchantflags/add-merchantflags" isAuthenticated={authentication.authenticated} component={AddMerchantFlags} level={[19, 10]} />
      <PrivateRoute exact path="/merchantflags/edit-merchantflags" isAuthenticated={authentication.authenticated} component={EditMerchantFlags} level={[19, 10]} />

      <PrivateRoute exact path="/bills-to-pay" isAuthenticated={authentication.authenticated} component={BillsToPay} level={[19, 10, 11, 12, 29]} />
      <PrivateRoute exact path="/settle-bills-to-pay" isAuthenticated={authentication.authenticated} component={SettleBillsToPay} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/bills-to-receive" isAuthenticated={authentication.authenticated} component={BillsToReceive} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/settle-bills-to-receive" isAuthenticated={authentication.authenticated} component={SettleBillsToReceive} level={[19, 10, 11, 12]} />

      <PrivateRoute exact path="/manifest" isAuthenticated={authentication.authenticated} component={Manifest} level={[19, 1, 2, 3, 10, 11, 12]} />
      <PrivateRoute exact path="/single-session" isAuthenticated={authentication.authenticated} component={SingleSession} level={[19, 1, 2, 3, 10, 11, 12]} />
      <PrivateRoute exact path="/single-session-transfer" isAuthenticated={authentication.authenticated} component={SingleSessionTransfer} level={[19, 1, 2, 3, 10, 11, 12]} />

      <PrivateRoute exact path="/session-closure" isAuthenticated={authentication.authenticated} component={SessionClosure} level={[19, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/session-closure/bills-to-receive" isAuthenticated={authentication.authenticated} component={SessionBillsToReceive} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/session-closure/bills-to-pay" isAuthenticated={authentication.authenticated} component={SessionBillsToPay} level={[19, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/session-closure/billing" isAuthenticated={authentication.authenticated} component={SessionBilling} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/session-closure/picture-of-the-day" isAuthenticated={authentication.authenticated} component={PictureOfTheDay} level={[19, 10, 11, 12]} />

      <PrivateRoute exact path="/users" isAuthenticated={authentication.authenticated} component={Users} level={[19, 4, 5, 22, 25, 29]} />
      <PrivateRoute exact path="/users/new-users" isAuthenticated={authentication.authenticated} component={NewUser} level={[19, 4, 5, 22, 25]} />
      <PrivateRoute exact path="/users/edit-users" isAuthenticated={authentication.authenticated} component={EditUser} level={[19, 4, 5, 22, 25, 29]} />

      <PrivateRoute exact path="/coupons" isAuthenticated={authentication.authenticated} component={Coupons} level={[19, 1, 2, 16, 17, 18]} />
      <PrivateRoute exact path="/commissions" isAuthenticated={authentication.authenticated} component={Commissions} level={[19, 10, 11, 12, 24, 25]} />

      <PrivateRoute exact path="/quotation" isAuthenticated={authentication.authenticated} component={Quotation} level={[19, 1, 2, 3]} />

      <PrivateRoute exact path="/sales-report" isAuthenticated={authentication.authenticated} component={SalesReport} level={[19, 1, 7, 8, 9, 10, 16, 23 ,24, 25, 29]} />
      <PrivateRoute exact path="/marketing-sales-report" isAuthenticated={authentication.authenticated} component={MarketingSalesReport} level={[19, 1, 7, 8, 9, 10, 16]} />
      {/* <PrivateRoute exact path="/chart" isAuthenticated={authentication.authenticated} component={Chart} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} /> */}

      <PrivateRoute exact path="/invoiced-sales" isAuthenticated={authentication.authenticated} component={InvoicedSales} level={[19, 24, 25]} />
      <PrivateRoute exact path="/pickup" isAuthenticated={authentication.authenticated} component={Pickup} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />

      <PrivateRoute exact path="/pickup/add-pickup" isAuthenticated={authentication.authenticated} component={AddPickup} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />
      <PrivateRoute exact path="/pickup/edit-pickup" isAuthenticated={authentication.authenticated} component={EditPickup} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />

      <PrivateRoute exact path="/revogar-cache" isAuthenticated={authentication.authenticated} component={RevokeCache} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />

      <PrivateRoute exact path="/decolar-import" isAuthenticated={authentication.authenticated} component={DecolarImport} level={[19, 1, 2, 3, 10, 11, 12]} />

      <PrivateRoute exact path="/products" isAuthenticated={authentication.authenticated} component={Products} level={[19]} />
      <PrivateRoute exact path="/products/list-products" isAuthenticated={authentication.authenticated} component={ProductList} level={[19]} />

      <PrivateRoute exact path="/channel/list-channels" isAuthenticated={authentication.authenticated} component={ListChannels} level={[19]} />
      <PrivateRoute exact path="/source/list-sources" isAuthenticated={authentication.authenticated} component={ListSources} level={[19]} />

      <PrivateRoute exact path="/supplier-tariff" isAuthenticated={authentication.authenticated} component={SuppliersTariff} level={[19, 1, 2, 3, 10, 11, 12]} />
      <PrivateRoute exact path="/supplier-tariff/add-tariff" isAuthenticated={authentication.authenticated} component={AddSuppliersTariff} level={[19, 1, 2, 3, 10, 11, 12]} />
      <PrivateRoute exact path="/supplier-tariff/edit-tariff" isAuthenticated={authentication.authenticated} component={EditSuppliersTariff} level={[19, 1, 2, 3, 10, 11, 12]} />
      <PrivateRoute exact path="/supplier-tariff/copy-tariff" isAuthenticated={authentication.authenticated} component={CopySuppliersTariff} level={[19, 1, 2, 3, 10, 11, 12]} />

      <PrivateRoute exact path="/report-financial" isAuthenticated={authentication.authenticated} component={ReportFinancial} level={[19, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/report-operatedsales" isAuthenticated={authentication.authenticated} component={ReportRioTour} level={[19, 4, 5, 6, 10, 11, 12]} />

      <PrivateRoute exact path="/accounts-extract" isAuthenticated={authentication.authenticated} component={AccountsExtract} level={[19, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/cashier-extract" isAuthenticated={authentication.authenticated} component={CashierExtract} level={[19, 3, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/report-cashflow" isAuthenticated={authentication.authenticated} component={ReportCashFlow} level={[19, 4, 5, 6, 10, 11, 12]} />

      <PrivateRoute exact path="/sessionless-reservations" isAuthenticated={authentication.authenticated} component={SessionlessOrders} level={[19, 2, 3, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/report-dailyreceipt" isAuthenticated={authentication.authenticated} component={ReportDailyReceipt} level={[19, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/report-sessionpend" isAuthenticated={authentication.authenticated} component={ReportSessionPend} level={[19, 4, 5, 6, 10, 11, 12]} />

      <PrivateRoute exact path="/bills-to-receive-erp" isAuthenticated={authentication.authenticated} component={BillsToReceiveERP} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/bills-to-pay-erp" isAuthenticated={authentication.authenticated} component={BillsToPayERP} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/rps-issuance" isAuthenticated={authentication.authenticated} component={RPSIssuance} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/card-conciliation" isAuthenticated={authentication.authenticated} component={CardConciliation} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/subordinate-extract" isAuthenticated={authentication.authenticated} component={SubordinateExtract} level={[19, 10, 11, 12, 24, 25]} />
      <PrivateRoute exact path="/report-cardconciliation" isAuthenticated={authentication.authenticated} component={ReportCardConciliation} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/export-erp/report-conference" isAuthenticated={authentication.authenticated} component={ReportERPConference} level={[19, 10, 11, 12]} />

      <PrivateRoute exact path="/export-erp/sales" isAuthenticated={authentication.authenticated} component={ExportERPSales} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/export-erp/suppliers" isAuthenticated={authentication.authenticated} component={ExportERPSuppliers} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/export-erp/spreadsheet" isAuthenticated={authentication.authenticated} component={ExportERPSpreadsheet} level={[19, 10, 11, 12]} />
      <PrivateRoute exact path="/export-erp/logs" isAuthenticated={authentication.authenticated} component={ExportERPLogs} level={[19, 10, 11, 12]} />

      <PrivateRoute exact path="/session-closed" isAuthenticated={authentication.authenticated} component={SessionClosed} level={[19, 4, 5, 6, 10, 11, 12]} />
      <PrivateRoute exact path="/sales-pending" isAuthenticated={authentication.authenticated} component={ReportSalesPend} level={[19, 4, 5, 6, 10, 11, 12]} />

      <PrivateRoute exact path="/products/list-transfer" isAuthenticated={authentication.authenticated} component={TransferList} level={[19]} />
      <PrivateRoute exact path="/products/add-transfer" isAuthenticated={authentication.authenticated} component={AddTransfer} level={[19]} />
      <PrivateRoute exact path="/products/edit-transfer" isAuthenticated={authentication.authenticated} component={EditTransfer} level={[19]} />

      <PrivateRoute exact path="/vehicles/list-vehicles" isAuthenticated={authentication.authenticated} component={Vehicles} level={[19]} />
      <PrivateRoute exact path="/vehicles/add-vehicle" isAuthenticated={authentication.authenticated} component={AddVehicle} level={[19]} />
      <PrivateRoute exact path="/vehicles/edit-vehicle" isAuthenticated={authentication.authenticated} component={EditVehicle} level={[19]} />

      <PrivateRoute exact path="/zones/list-zones" isAuthenticated={authentication.authenticated} component={Zones} level={[19]} />
      <PrivateRoute exact path="/zones/add-zone" isAuthenticated={authentication.authenticated} component={AddZone} level={[19]} />
      <PrivateRoute exact path="/zones/edit-zone" isAuthenticated={authentication.authenticated} component={EditZone} level={[19]} />

      <PrivateRoute exact path="/listar-categorias" isAuthenticated={authentication.authenticated} component={ListCategories} level={[19]} />
      <PrivateRoute exact path="/listar-categorias/adicionar-categorias" isAuthenticated={authentication.authenticated} component={AddCategory} level={[19]} />
      <PrivateRoute exact path="/editar-categoria" isAuthenticated={authentication.authenticated} component={EditCategory} level={[19]} />

      <PrivateRoute exact path="/listar-resources" isAuthenticated={authentication.authenticated} component={Resources} level={[19]} />
      <PrivateRoute exact path="/adicionar-resource" isAuthenticated={authentication.authenticated} component={AddResource} level={[19]} />
      <PrivateRoute exact path="/editar-resource" isAuthenticated={authentication.authenticated} component={EditResource} level={[19]} />
      <PrivateRoute exact path="/vehicle-ranking" isAuthenticated={authentication.authenticated} component={ResourcesVehicleRanking} level={[19]} />

      <PrivateRoute exact path="/resource-type" isAuthenticated={authentication.authenticated} component={ResourceType} level={[19]} />
      <PrivateRoute exact path="/add-resource-type" isAuthenticated={authentication.authenticated} component={AddResourceType} level={[19]} />
      <PrivateRoute exact path="/edit-resource-type" isAuthenticated={authentication.authenticated} component={EditResourceType} level={[19]} />

      <PrivateRoute exact path="/associar-resource" isAuthenticated={authentication.authenticated} component={ResourceToAssociate} level={[19]} />

      <PrivateRoute exact path="/products/add-tour" isAuthenticated={authentication.authenticated} component={AddTour} level={[19]} />
      <PrivateRoute exact path="/products/migration-tour" isAuthenticated={authentication.authenticated} component={MigrationTour} level={[19]} />
      <PrivateRoute exact path="/products/edit-tour" isAuthenticated={authentication.authenticated} component={EditTour} level={[19]} />

      <PrivateRoute exact path="/products/add-ticket" isAuthenticated={authentication.authenticated} component={AddTicket} level={[19]} />
      <PrivateRoute exact path="/products/edit-ticket" isAuthenticated={authentication.authenticated} component={EditTicket} level={[19]} />

      <PrivateRoute exact path="/products/stock" isAuthenticated={authentication.authenticated} component={ListStock} level={[19]} />
      <PrivateRoute exact path="/products/add-stock" isAuthenticated={authentication.authenticated} component={Stock} level={[19]} />
      <PrivateRoute exact path="/products/add-stock-group" isAuthenticated={authentication.authenticated} component={StockGroupSession} level={[19]} />
      <PrivateRoute exact path="/products/edit-stock-group" isAuthenticated={authentication.authenticated} component={EditStockGroupSession} level={[19]} />
      <PrivateRoute exact path="/products/list-stock-group" isAuthenticated={authentication.authenticated} component={ListStockGroupSession} level={[19]} />

      <PrivateRoute exact path="/products/add-combo" isAuthenticated={authentication.authenticated} component={AddCombo} level={[19]} />
      <PrivateRoute exact path="/products/edit-combo" isAuthenticated={authentication.authenticated} component={EditCombo} level={[19]} />
      <PrivateRoute exact path="/products/list-combo" isAuthenticated={authentication.authenticated} component={ListCombo} level={[19]} />

      <PrivateRoute exact path="/blackout/listar-blackouts" isAuthenticated={authentication.authenticated} component={ListBlackout} level={[19]} />
      <PrivateRoute exact path="/blackout/add-blackout" isAuthenticated={authentication.authenticated} component={AddBlackout} level={[19]} />
      <PrivateRoute exact path="/blackout/edit-blackout" isAuthenticated={authentication.authenticated} component={EditBlackout} level={[19]} />

      <PrivateRoute exact path="/listar-associar-categorias" isAuthenticated={authentication.authenticated} component={ListAssociation} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />
      <PrivateRoute exact path="/associar-categorias" isAuthenticated={authentication.authenticated} component={AssociateCategories} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />

      <PrivateRoute exact path="/editar-associacao" isAuthenticated={authentication.authenticated} component={EditAssociation} level={[19, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]} />
      
      <PrivateRoute exact path="/groups-customized" isAuthenticated={authentication.authenticated} component={AddGroupFile} level={[19, 1, 2, 3, 7, 8, 9, 10, 23, 24, 25, 29]} />
      <PrivateRoute exact path="/edit-groups-customized" isAuthenticated={authentication.authenticated} component={EditGroupFile} level={[19, 1, 2, 3, 7, 8, 9, 10, 23, 24, 25, 29]} />
      <PrivateRoute exact path="/list-groups-customized" isAuthenticated={authentication.authenticated} component={GroupsAndCustomized} level={[19, 1, 2, 3, 10, 11, 12, 13, 14, 16, 17, 23, 24, 25, 26, 29]} />
      <PrivateRoute exact path="/copy-groups-customized" isAuthenticated={authentication.authenticated} component={CopyGroupFile} level={[19, 1, 2, 3, 10, 11, 12, 13, 14, 16, 17, 23, 24, 25, 26, 29]} />


      <PrivateRoute exact path="/add-banners" isAuthenticated={authentication.authenticated} component={AddBanners} level={[19, 1, 2, 3, 10, 11, 12]} />
      <Route path="/404" exact component={NotFound} />
      <Route component={NotFound} />

      {!(process.env.REACT_APP_PRODUCTION === 'TRUE') &&
        <>

          <PrivateRoute exact path="/opening-hours" isAuthenticated={authentication.authenticated} component={OpeningHours} level={[19, 1, 2, 3, 10, 11, 12]} />
          <PrivateRoute exact path="/modal-control" isAuthenticated={authentication.authenticated} component={ModalControl} level={[19, 1, 2, 3, 10, 11, 12]} />
        </>
      }

      
    </Switch>
  );
}

export default Routes;